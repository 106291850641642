<template lang="">
<div class="overview">
    <div class="date">
        <el-radio-group v-model="type" style="margin-right:10px">
            <el-radio label="1">月</el-radio>
            <el-radio label="2">周</el-radio>
        </el-radio-group>
        <el-date-picker v-if="type==2" :picker-options="{'firstDayOfWeek': 1}" v-model="inputweek" size="small" :clearable="false" value-format="yyyy-MM-dd" type="week" format="yyyy 第 WW 周" placeholder="选择周" @change="getData()">
        </el-date-picker>

        <el-date-picker v-if="type==1" v-model="inputmonth" size="small" type="month" :clearable="false" value-format="yyyy-MM-dd" format="yyyy年MM月" placeholder="选择月" @change="getData()">
        </el-date-picker>
    </div>
	<div class="changeBox">
		<!-- <el-button type="primary" size="mini" @click="isVertical=!isVertical">切换方向</el-button> -->
	</div>
    <section class="">
        <TreeChart v-if="isShow" :json="treeData" :range="range" :inputweek="inputweek" :inputmonth="inputmonth" :type="type" :class="{landscape: isVertical}" />
    </section>
</div>
</template>

<script>
import TreeChart from '@/components/treeData'
export default {
    components: {
        TreeChart
    },
    data() {
        return {
            isShow: false,
            treeData: {
                // ext_department_name: this.$root.userInfo.ext_company_name,
                // id: 0,
            },
            isVertical: false, // 是否是竖方向,只给最外层的添加
            isDetail: true, // 是否是详情,不可编辑操作
            type: '',
            inputweek: '',
            inputmonth: '',
            list: [],
            sublist: [],
            showId: 0,
            range: {}
        }
    },
    watch: {
        inputweek: function (val) {
            sessionStorage.setItem('inputweekSelf', this.inputweek)
        },
        inputmonth: function (val) {
            sessionStorage.setItem('inputmonthSelf', this.inputmonth)
        },
        type() {
            if (sessionStorage.getItem('inputmonthSelf')) {
                this.inputmonth = sessionStorage.getItem('inputmonthSelf')
            } else {
                this.inputmonth = this.$common.getFirstLastDay('month')[0];
            }
            if (sessionStorage.getItem('inputweekSelf')) {
                this.inputweek = sessionStorage.getItem('inputweekSelf')
            }
            sessionStorage.setItem('typeSelf', this.type)
            this.getData();
        }
    },
    mounted() {
        this.getSetting();
        if (this.$route.params.form == 'login') this.$router.go(0);

        // 禁用浏览器返回键
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', this.disableBrowserBack);

    },
    methods: {
        getSetting() {
            this.http.post('/admin.sysRemind/remindAndMeetRead').then(response => {
                if (sessionStorage.getItem('typeSelf')) {
                    this.type = sessionStorage.getItem('typeSelf')
                    this.inputmonth = sessionStorage.getItem('inputmonthSelf')
                    this.inputweek = sessionStorage.getItem('inputweekSelf')
                } else {
                    this.type = '2';
                }
                if (sessionStorage.getItem('typeSelf')) return;
                if (this.type == '1') {
                    if(sessionStorage.getItem('inputweekSelf')) return;
                    if (sessionStorage.getItem('inputmonthSelf') && response.meet_month_status == 2) {
                        this.inputmonth = this.$common.getPreDate('month');
                    } else {
                        this.inputmonth = this.$common.getFirstLastDay('month')[0];
                    }
                } else {
                    if(sessionStorage.getItem('inputweekSelf')) return;
                    if (response.meet_week_status == 2) {
                        this.inputweek = this.$common.getPreDate('week');
                    } else {
                        this.inputweek = this.$common.getFirstLastDay('week')[0];
                    }
                }
            })
        },
        getData() {
            this.http.post('/admin.user/indexWorkOutLook', {
                type: this.type,
                input: this.type == 1 ? this.inputmonth : this.inputweek,
            }).then(re => {
                this.treeData = {
                    ext_department_name: this.$root.userInfo.ext_company_name,
                    id: 0,
                    son: re.data
                }
                this.range = re.time;
                this.isShow = true
            })
        },
        dateInit() {
            if (this.type == '1') {
                this.inputmonth = this.$common.getFirstLastDay('month')[0];
            } else if (this.type == '2') {
                this.inputweek = this.$common.getFirstLastDay('week')[0];
            }
        },
        disableBrowserBack() {
            history.pushState(null, null, document.URL);
        },
    },
    destroyed() {
        // 清除popstate事件 否则会影响到其他页面
        window.removeEventListener("popstate", this.disableBrowserBack, false);
    },
}
</script>
